import React from "react";

export default function index() {
	return (
		<div className='flex h-screen w-screen'>
			<div className='m-auto text-center text-7xl font-bold'>
				Website Under Maintenance
			</div>
		</div>
	);
}
